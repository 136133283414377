'use client'
import { useEffect, useMemo, useRef, useState } from 'react'
import { ArrowLeft, BadgeCheck, X } from 'lucide-react'
import { useTranslations } from 'next-intl'

import { GetProductInfo } from './info'
import { GetProductSelectType } from './select-type'
import { Button, Dialog } from '@/presentation/components/shared'

import styles from './styles.module.scss'

type Step = 'select-type' | 'info' | 'confirmed-purchase'

export function GetProduct() {
  const t = useTranslations('components.pages.home.GetProduct')
  const type = useRef<'like' | 'comment'>()
  const [step, setStep] = useState<Step>('select-type')
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const text = useMemo(() => {
    const strategy: Record<Step, string> = {
      'confirmed-purchase': '',
      'select-type': t('SELECT_TYPE_TITLE'),
      info: t('INFO_TITLE'),
    }

    return strategy[step]
  }, [step])

  const onSelectTypeSubmit = (input: 'like' | 'comment') => {
    type.current = input
    setStep('info')
  }

  const onInfoSubmit = () => {
    setStep('confirmed-purchase')
  }

  useEffect(() => {
    if (!isDialogOpen) {
      type.current = undefined
      setStep('select-type')
    }
  }, [isDialogOpen])

  return (
    <Dialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <Dialog.Trigger>
        <Button.Root>
          <Button.Text>{t('BUTTON_TEXT')}</Button.Text>
        </Button.Root>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay />

        <Dialog.Content className={styles.dialogContent}>
          <Dialog.Close className={styles.close}>
            <X color="#DEE2E6" size={20} />
          </Dialog.Close>

          <Dialog.Close className={styles.back}>
            <ArrowLeft size={24} color="#495057" />
          </Dialog.Close>

          {step !== 'confirmed-purchase' && (
            <header>
              <strong>{text}</strong>
            </header>
          )}

          {step === 'select-type' && (
            <GetProductSelectType callback={onSelectTypeSubmit} />
          )}

          {step === 'info' && (
            <GetProductInfo type={type.current!} callback={onInfoSubmit} />
          )}

          {step === 'confirmed-purchase' && (
            <section className={styles.confirmedPurchase}>
              <header>
                <div>
                  <BadgeCheck size={32} color="var(--blue-500)" />
                </div>

                <strong>{t('CONFIRMED_ORDER')}</strong>
              </header>

              <div>
                <p>
                  {t.rich('CONFIRMED_ORDER_DESCRIPTION', {
                    br: () => <br />,
                  })}
                </p>

                <p>{t('THANKS')}</p>
              </div>
            </section>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
