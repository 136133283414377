import { HttpClient } from '@/data/protocols/http'
import { ILikePostUseCase } from '@/domain/usecases/social-media'

export class LikePostUseCase implements ILikePostUseCase {
  constructor(private readonly http: HttpClient) {}

  async execute(
    input: ILikePostUseCase.Input,
  ): Promise<ILikePostUseCase.Output> {
    await this.http.on({
      url: 'https://prod.api.getfanzo.io/v1/social-media-service/social/7a33a707-bf2c-4f76-be89-dbc34ba7d481/post/instagram/like',
      method: 'POST',
      body: {
        influencer_id: '9eaf460e-7b77-412c-a78d-00011faece4f',
        post_url: input.postUrl,
      },
      headers: {
        Authorization: input.accessToken,
      },
    })
  }
}
