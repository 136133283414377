import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/presentation/components/pages/home/GetProduct/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/presentation/components/pages/home/Hero/styles.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/src/presentation/components/shared/Dialog/Close/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/presentation/components/shared/Dialog/Content/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/presentation/components/shared/Dialog/Overlay/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/presentation/components/shared/Dialog/Portal/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/presentation/components/shared/Dialog/Root/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/presentation/components/shared/Dialog/Trigger/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/presentation/components/shared/Header/currencies.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/presentation/components/shared/Header/header-link.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/presentation/components/shared/Header/language.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/presentation/components/shared/Header/mobile-nav.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/presentation/components/shared/Header/profile.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/presentation/components/shared/OTPInput/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/presentation/components/shared/Slider/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/presentation/components/shared/Toast/close-button.tsx")