import { HttpClient } from '@/data/protocols/http'

export class FetchAdapter implements HttpClient {
  async on<T>(input: HttpClient.Input): Promise<HttpClient.Output<T>> {
    const getUrl = (): string => {
      const url = new URL(input.url)

      if (input.method === 'GET' && input.params) {
        Object.entries(input.params).forEach(([key, value]) => {
          if (value !== undefined) {
            url.searchParams.append(key, value)
          }
        })
      }

      return url.toString()
    }

    const url = getUrl()
    const [, tag] = url.split(process.env.NEXT_PUBLIC_BASE_API_URL!)

    const response = await fetch(url, {
      method: input.method,
      headers: {
        'Content-Type': 'application/json',
        ...input.headers,
      },
      body: JSON.stringify(input.body),
      next: {
        tags: [tag],
        revalidate: input.revalidate || undefined,
      },
    })
    const data = await response.json()

    if (!response.ok) {
      throw data
    }

    return data
  }
}
