'use client'
import { useMemo } from 'react'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { useTranslations } from 'next-intl'

import { FetchAdapter } from '@/infra/http'
import { SonnerAdapter } from '@/infra/toast'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Input } from '@/presentation/components/shared'
import {
  CommentInPostUseCase,
  LikePostUseCase,
} from '@/data/usecases/social-media'

import styles from './styles.module.scss'
import { getCookie, setCookie } from '@/presentation/actions'
import { AUTHENTICATED_USER_COOKIE_KEY } from '@/presentation/constants'
import { UserCookiePayload } from '@/domain/models'
import { add, isPast } from 'date-fns'

interface GetProductInfoProps {
  type: 'like' | 'comment'
  callback(): void
}

const Schema = z.object({
  link: z
    .string()
    .url({ message: 'errors.client.home.get-product-schema.link.url' }),
  comment: z.string().optional(),
})

export function GetProductInfo({ type, callback }: GetProductInfoProps) {
  const t = useTranslations('components.pages.home.GetProduct')
  const rootTranslations = useTranslations()
  const { toast } = new SonnerAdapter()

  const { handleSubmit, register, formState } = useForm<z.infer<typeof Schema>>(
    {
      resolver: zodResolver(Schema),
    },
  )

  const errors = useMemo((): Record<
    keyof z.infer<typeof Schema>,
    string | undefined
  > => {
    const translateError = (
      error: keyof z.infer<typeof Schema>,
    ): string | undefined => {
      const field = formState.errors[error]
      return field ? rootTranslations(field.message as any) : undefined
    }

    return {
      comment: '',
      link: translateError('link'),
    }
  }, [formState.errors])

  const onSubmit = async (data: z.infer<typeof Schema>) => {
    try {
      if (!(type === 'comment' || type === 'like')) {
        throw new Error('Type is invalid')
      }

      const http = new FetchAdapter()

      const { value: authOptions } = await getCookie<UserCookiePayload>(
        AUTHENTICATED_USER_COOKIE_KEY,
      )

      if (authOptions) {
        let accessToken = authOptions.accessToken

        if (isPast(authOptions.expiredAt)) {
          const response = await http.on<{
            access_token: string
            expires_in: number
          }>({
            method: 'POST',
            url: 'https://prod.api.getfanzo.io/v1/users-service/public/auth/refresh',
            body: {
              refresh_token: authOptions.refreshToken,
            },
          })

          if (response.data) {
            accessToken = response.data.access_token

            await setCookie({
              name: AUTHENTICATED_USER_COOKIE_KEY,
              value: {
                userId: authOptions.userId,
                name: authOptions.name,
                email: authOptions.email,
                role: authOptions.role,
                accessToken: response.data.access_token,
                refreshToken: authOptions.refreshToken,
                expiredAt: add(new Date(), {
                  seconds: response.data.expires_in,
                }),
              },
              options: {
                expires: add(new Date(), { months: 1 }),
              },
            })
          }
        }

        if (type === 'comment') {
          if (!data.comment) {
            throw new Error('Comment is invalid')
          }

          const commentInPostUseCase = new CommentInPostUseCase(http)
          await commentInPostUseCase.execute({
            postUrl: data.link,
            content: data.comment,
            accessToken,
          })
        }

        if (type === 'like') {
          const likePostUseCase = new LikePostUseCase(http)
          await likePostUseCase.execute({
            postUrl: data.link,
            accessToken,
          })
        }
      }

      callback()
    } catch (error) {
      toast({
        status: 'error',
        text: rootTranslations('errors.default'),
      })
    }
  }

  return (
    <section className={styles.info}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label={t('LINK_LABEL')}
          placeholder={t('LINK_PLACEHOLDER')}
          error={errors.link}
          {...register('link')}
        />

        {type === 'comment' && (
          <Input
            label={t('COMMENT_LABEL')}
            placeholder={t('COMMENT_PLACEHOLDER')}
            error={errors.comment}
            {...register('comment')}
          />
        )}

        <Button.Root type="submit" isLoading={formState.isSubmitting}>
          <Button.Text>{t('NEXT')}</Button.Text>
        </Button.Root>
      </form>
    </section>
  )
}
