import { toast as sonnerToast, Toaster as SonnerToaster } from 'sonner'

import { Toast, Toaster } from '@/data/protocols/toast'
import { Toast as ToastComponent } from '@/presentation/components/shared'

export class SonnerAdapter implements Toast, Toaster {
  toast(input: Toast.Input): Toast.Output {
    sonnerToast.custom(
      (t) => (
        <ToastComponent
          status={input.status}
          text={input.text}
          handleClose={() => sonnerToast.dismiss(t)}
        />
      ),
      { duration: 1000 * 10 }, // 10 seconds
    )
  }

  toaster(): Toaster.Output {
    return (
      <SonnerToaster
        position="bottom-left"
        visibleToasts={3}
        style={{ width: '100%' }}
      />
    )
  }
}
