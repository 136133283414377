'use client'
import { useState } from 'react'
import { useTranslations } from 'next-intl'
import { Heart, MessageCircle } from 'lucide-react'

import { SonnerAdapter } from '@/infra/toast'
import { Button, CheckboxGroup } from '@/presentation/components/shared'

import styles from './styles.module.scss'

type ProductType = 'like' | 'comment'

interface GetProductSelectTypeProps {
  callback(type: ProductType): void
}

export function GetProductSelectType({ callback }: GetProductSelectTypeProps) {
  const t = useTranslations('components.pages.home.GetProduct')
  const [type, setType] = useState<ProductType>()

  const { toast } = new SonnerAdapter()

  const handleSubmit = () => {
    if (!(type === 'like' || type === 'comment')) {
      toast({
        text: 'Selecione um tipo',
        status: 'error',
      })
      return
    }

    callback(type)
  }

  return (
    <section className={styles.type}>
      <CheckboxGroup.Root
        isActive={type === 'like'}
        onChange={() => setType('like')}
      >
        <CheckboxGroup.Icon>
          <Heart size={20} color="var(--blue-500)" />
        </CheckboxGroup.Icon>

        <CheckboxGroup.Text>
          <strong>{t('LIKE')}</strong>
        </CheckboxGroup.Text>
      </CheckboxGroup.Root>

      <CheckboxGroup.Root
        isActive={type === 'comment'}
        onChange={() => setType('comment')}
      >
        <CheckboxGroup.Icon>
          <MessageCircle size={20} color="var(--blue-500)" />
        </CheckboxGroup.Icon>

        <CheckboxGroup.Text>
          <strong>{t('COMMENT')}</strong>
        </CheckboxGroup.Text>
      </CheckboxGroup.Root>

      <Button.Root onClick={handleSubmit}>
        <Button.Text>{t('NEXT')}</Button.Text>
      </Button.Root>
    </section>
  )
}
